import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/aml',
    name: RouteName.AML,
    redirect: { name: RouteName.AmlPendingReview },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'pending-review',
        name: RouteName.AmlPendingReview,
        component: () => import('@/views/ViewAml/ViewAmlPendingReview.vue'),
      },
      {
        path: 'on-hold',
        name: RouteName.AmlOnHold,
        component: () => import('@/views/ViewAml/ViewAmlOnHold.vue'),
      },
      {
        path: 'return-requests',
        name: RouteName.RefundOrders,
        component: () => import('@/views/ViewAml/ViewAmlRefundOrders.vue'),
      },
    ],
  },
  {
    path: '/aml/:id',
    name: RouteName.AmlDetails,
    component: () => import('@/views/ViewAml/ViewAmlDetails.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/aml/return-requests/:id',
    name: RouteName.RefundOrdersDetails,
    component: () => import('@/views/ViewAml/ViewAmlRefundOrdersDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
