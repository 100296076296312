<template>
  <QToolbar>
    <QBtn dense flat round icon="menu" @click="$emit(AppEvent.Toggle)" />

    <!--    <QTabs align="left">-->
    <!--      <QRouteTab to="/page1" label="Page One" />-->
    <!--      <QRouteTab to="/page2" label="Page Two" />-->
    <!--      <QRouteTab to="/page3" label="Page Three" />-->
    <!--    </QTabs>-->

    <QBtn
      dense
      flat
      round
      :icon="themeIcon"
      class="q-ml-auto"
      @click="toggleDarkMode"
    />

    <AppToolbarUser />
  </QToolbar>
</template>

<script setup lang="ts">
import { AppEvent } from '@/app/types/app/events';

import useDarkMode from '@/composables/common/useDarkMode';

import AppToolbarUser from '@C/layout/AppToolbar/AppToolbarUser.vue';

interface Emits {
  (event: AppEvent.Toggle): void;
}

defineEmits<Emits>();

const { themeIcon, toggleDarkMode } = useDarkMode();
</script>
