import { NavigationGuard } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import cookies from '@/plugins/cookies';

import env from '@/app/env';

const authGuard: NavigationGuard = (to, from, next) => {
  const token = cookies.get(env.cookieName);

  if (token) {
    next({ name: RouteName.Home });
  }

  next();
};

export default authGuard;
