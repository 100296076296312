import { useI18n } from 'vue-i18n';

import { RouteName } from '@/app/types/app/routes';

export default function () {
  const { t } = useI18n();

  const navLinks = [
    {
      title: t('common.pages.dashboard'),
      to: RouteName.Dashboard,
      icon: 'dashboard',
    },
    {
      title: t('common.pages.organizations'),
      to: RouteName.Organizations,
      icon: 'domain',
    },
    {
      title: t('common.pages.users'),
      to: RouteName.Users,
      icon: 'group',
    },
    {
      title: t('common.pages.orgAccounts'),
      to: RouteName.OrgAccounts,
      icon: 'account_balance_wallet',
    },
    {
      title: t('common.pages.withdrawRequests'),
      icon: 'receipt_long',
      to: RouteName.WithdrawRequests,
      isOpened: false,
      sublinks: [
        {
          title: t('common.pages.cryptoWithdrawRequests'),
          to: RouteName.CryptoWithdrawRequests,
        },
        {
          title: t('common.pages.fiatWithdrawRequests'),
          to: RouteName.FiatWithdrawRequests,
        },
      ],
    },
    {
      title: t('common.pages.exchangeRequests'),
      icon: 'currency_exchange',
      to: RouteName.ExchangeRequests,
    },
    {
      title: t('common.pages.transactions'),
      icon: 'request_page',
      to: RouteName.Transactions,
      isOpened: false,
      sublinks: [
        {
          title: t('common.pages.cryptoTransactions'),
          to: RouteName.CryptoTransactions,
        },
        {
          title: t('common.pages.fiatTransactions'),
          to: RouteName.FiatTransactions,
        },
        {
          title: t('common.pages.pendingCryptoTransactions'),
          to: RouteName.PendingCryptoTransaction,
        },
      ],
    },
    {
      title: t('common.pages.paygateSessions'),
      icon: 'payments',
      to: RouteName.PaygateSessions,
    },
    {
      title: t('common.pages.bankAccounts'),
      icon: 'account_balance',
      to: RouteName.BankAccounts,
    },
    {
      title: t('common.pages.paymentAuditHistory'),
      icon: 'content_paste_search',
      to: RouteName.PaymentAuditHistory,
    },
    {
      title: t('common.pages.aml'),
      icon: 'assured_workload',
      to: RouteName.AML,
      isOpened: false,
      sublinks: [
        {
          title: t('common.pages.amlPendingReview'),
          to: RouteName.AmlPendingReview,
        },
        {
          title: t('common.pages.amlOnHold'),
          to: RouteName.AmlOnHold,
        },
        {
          title: t('common.pages.amlReturnRequests'),
          to: RouteName.RefundOrders,
        },
      ],
    },
    {
      title: t('common.pages.documents'),
      icon: 'receipt',
      to: RouteName.Documents,
      isOpened: false,
      sublinks: [
        {
          title: t('common.pages.fundingInstructions'),
          to: RouteName.FundingInstructions,
        },
        {
          title: t('common.pages.transactionReports'),
          to: RouteName.TransactionReports,
        },
        {
          title: t('common.pages.invoices'),
          to: RouteName.Invoices,
        },
      ],
    },
    // settings
    {
      title: t('common.pages.settings'),
      icon: 'settings',
      to: RouteName.Settings,
      isOpened: false,
      sublinks: [
        {
          title: t('common.pages.kytRotator'),
          to: RouteName.KytRotator,
        },
        {
          title: t('common.pages.activeAddresses'),
          to: RouteName.ActiveAddresses,
        },
        {
          title: t('common.pages.restrictedAddresses'),
          to: RouteName.RestrictedAddresses,
        },
        {
          title: t('common.pages.restrictedIps'),
          to: RouteName.RestrictedIps,
        },
        {
          title: t('common.pages.referralPartners'),
          to: RouteName.ReferralPartners,
        },
        {
          title: t('common.pages.allowedAssets'),
          to: RouteName.AllowedAssets,
        },
      ],
    },
    // security
    {
      title: t('common.pages.security'),
      icon: 'policy',
      to: RouteName.Security,
      sublinks: [
        {
          title: t('common.pages.backofficeLogs'),
          to: RouteName.BackofficeLogs,
        },
      ],
    },
    // tools
    {
      title: t('common.pages.tools'),
      icon: 'construction',
      to: RouteName.Tools,
      isOpened: true,
      sublinks: [
        {
          title: t('common.pages.matchTransactionSession'),
          to: RouteName.MatchTransactionSession,
        },
        {
          title: t('common.pages.matchTransactionAccount'),
          to: RouteName.MatchTransactionAccount,
        },
        {
          title: t('common.pages.addTransaction'),
          to: RouteName.AddTransaction,
        },
        {
          title: t('common.pages.balanceControl'),
          to: RouteName.BalanceControl,
        },
        {
          title: t('common.pages.balanceOverview'),
          to: RouteName.BalanceOverview,
        },
        {
          title: t('common.pages.pendingWithdrawCsv'),
          to: RouteName.PendingWithdrawCsv,
        },
        {
          title: t('common.pages.paymentAudit'),
          to: RouteName.PaymentAudit,
        },
      ],
    },
  ];

  return { navLinks };
}
