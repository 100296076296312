import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/exchange-requests',
    name: RouteName.ExchangeRequests,
    component: () =>
      import('@/views/ViewExchangeRequests/ViewExchangeRequests.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/exchange-requests/:id',
    name: RouteName.ExchangeRequest,
    component: () =>
      import('@/views/ViewExchangeRequests/ViewExchangeRequestDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
