import format from 'quasar/src/utils/format.js';;

import {
  CryptoCurrency,
  CryptoCurrencyBlockchain,
  ExtendedCryptoCurrency,
} from '@/app/types/app/currency';

import CoinTypeName from '@/app/CoinTypeName';

type BlockchainAddition = {
  [key in CryptoCurrencyBlockchain]?: string;
};

export default function (
  cryptocurrency: CryptoCurrency,
): ExtendedCryptoCurrency {
  const blockchainNameShortcut: BlockchainAddition = {
    [CryptoCurrencyBlockchain.BinanceSmartChain]: 'BSC',
  };

  const blockchainName =
    blockchainNameShortcut[cryptocurrency.blockchain] ??
    format.capitalize(cryptocurrency.blockchain);
  const coinTypeName = new CoinTypeName(
    cryptocurrency.blockchain,
    cryptocurrency.coin_type,
  );
  const blockchainFullName = `${blockchainName} ${coinTypeName.valueWrapped}`;
  const currencyCodeAndName = `${cryptocurrency.code} - ${cryptocurrency.name}`;
  const currencyCodeAndBlockchain = `${cryptocurrency.code} - ${blockchainFullName}`;

  return {
    ...cryptocurrency,
    blockchainName,
    coinTypeName,
    blockchainFullName,
    currencyCodeAndName,
    currencyCodeAndBlockchain,
  };
}
