<template>
  <QList>
    <template v-for="link of navLinks" :key="link.title">
      <QExpansionItem
        v-if="link.sublinks"
        :icon="link.icon"
        :label="link.title"
        :to="{ name: link.to }"
        :default-opened="link.isOpened"
      >
        <QItem
          v-for="sublink of link.sublinks"
          :key="sublink.title"
          :to="{ name: sublink.to }"
          clickable
        >
          <QItemSection>
            <QItemLabel>{{ sublink.title }}</QItemLabel>
          </QItemSection>
        </QItem>
      </QExpansionItem>

      <QItem v-else :to="{ name: link.to }" clickable>
        <QItemSection avatar>
          <QIcon :name="link.icon" />
        </QItemSection>

        <QItemSection>
          <QItemLabel>{{ link.title }}</QItemLabel>
        </QItemSection>
      </QItem>
    </template>
  </QList>
</template>

<script setup lang="ts">
import useAppNavigation from '@/composables/common/useAppNavigation';

const { navLinks } = useAppNavigation();
</script>
