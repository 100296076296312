import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/users',
    name: RouteName.Users,
    component: () => import('@/views/ViewUsers/ViewUsers.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/users/:id',
    name: RouteName.User,
    component: () => import('@/views/ViewUsers/ViewUserDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
