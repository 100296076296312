export default {
  emptyMessageText: 'No users found.\n\nTry other filter settings.',
  blockUser: 'Block user',
  activateUser: 'Activate user',
  changeStatusSuccess: 'User status changed successfully',
  updateUserSuccess: 'User details changed successfully',
  details: {
    overview: 'Profile details',
    userEdit: 'Edit user profile',
  },
};
