import aml from '@/lang/en/modules/aml';
import bankAccounts from '@/lang/en/modules/bank-accounts';
import common from '@/lang/en/modules/common';
import documents from '@/lang/en/modules/documents';
import errors from '@/lang/en/modules/errors';
import exchangeRequests from '@/lang/en/modules/exchange-requests';
import login from '@/lang/en/modules/login';
import modals from '@/lang/en/modules/modals';
import orgAccounts from '@/lang/en/modules/org-accounts';
import organizations from '@/lang/en/modules/organizations';
import paygateSessions from '@/lang/en/modules/paygate-sessions';
import paymentAudits from '@/lang/en/modules/payment-audits';
import servicePages from '@/lang/en/modules/service-pages';
import settings from '@/lang/en/modules/settings';
import tools from '@/lang/en/modules/tools';
import transactions from '@/lang/en/modules/transactions';
import users from '@/lang/en/modules/users';
import withdrawRequests from '@/lang/en/modules/withdraw-requests';

export default {
  common,
  errors,
  login,
  users,
  tools,
  modals,
  withdrawRequests,
  transactions,
  organizations,
  exchangeRequests,
  bankAccounts,
  settings,
  paygateSessions,
  orgAccounts,
  documents,
  paymentAudits,
  aml,
  servicePages,
};
