import { GetterTree, MutationTree } from 'vuex';

import { BankAccount } from '@/app/types/api/resources/banks';
import {
  JurisdictionsResponse,
  KYTProviderResponse,
} from '@/app/types/api/resources/dictionaries';
import {
  CryptoCurrency,
  CryptoCurrencyCoinType,
  Currency,
  ExtendedCryptoCurrency,
} from '@/app/types/app/currency';
import {
  DictionariesGetters,
  DictionariesMutations,
  DictionariesState,
} from '@/app/types/store/modules/dictionaries';
import { Store } from '@/app/types/store/store';

import extendCryptoCurrency from '@/app/helpers/extendCryptoCurrency';

const state = (): DictionariesState => ({
  currencies: [],
  cryptocurrencies: [],
  jurisdictions: [],
  inxyBankAccounts: [],
  kytProviders: [],
});

const mutations: MutationTree<DictionariesState> & DictionariesMutations = {
  setCurrencies(state: DictionariesState, currencies: Currency[]) {
    state.currencies = currencies;
  },

  setCryptoCurrencies(
    state: DictionariesState,
    cryptocurrencies: CryptoCurrency[],
  ) {
    state.cryptocurrencies = cryptocurrencies;
  },

  setJurisdictions(
    state: DictionariesState,
    jurisdictions: JurisdictionsResponse[],
  ) {
    state.jurisdictions = jurisdictions;
  },

  setInxyBankAccounts(state: DictionariesState, bankAccounts: BankAccount[]) {
    state.inxyBankAccounts = bankAccounts;
  },

  setKytProviders(
    state: DictionariesState,
    kytProviders: KYTProviderResponse[],
  ) {
    state.kytProviders = kytProviders;
  },
};

const getters: GetterTree<DictionariesState, Store> & DictionariesGetters = {
  extendedCryptoCurrencies: (
    state: DictionariesState,
  ): ExtendedCryptoCurrency[] =>
    state.cryptocurrencies.map((crypto) => extendCryptoCurrency(crypto)),
  getNativeCrypto: (state: DictionariesState) => (crypto: CryptoCurrency) => {
    if (!crypto) {
      return null;
    }

    const isCryptoIsNative = crypto.coin_type === CryptoCurrencyCoinType.Native;

    return isCryptoIsNative
      ? crypto
      : state.cryptocurrencies.find(
          (el) =>
            el.blockchain === crypto.blockchain &&
            el.coin_type === CryptoCurrencyCoinType.Native,
        ) ?? null;
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
  actions: {},
};
