import {
  AxiosPaginatedResponse,
  DictionaryResponse,
} from '@/app/types/api/config';
import {
  User,
  UserDetailsRequestBody,
  UsersApi,
  UserStatusRequestBody,
} from '@/app/types/api/resources/users';
import { RequestParams } from '@/app/types/app/pagination';

import client from '@/app/api/client';
import getUrlWithId from '@/app/helpers/getUrlWithId';

export default {
  fetchMe(): Promise<DictionaryResponse<User>> {
    return client.get(UsersApi.MyUser);
  },

  fetchUsers(params: RequestParams): Promise<AxiosPaginatedResponse<User[]>> {
    return client.get(UsersApi.Users, { params });
  },

  fetchUser(id: string): Promise<DictionaryResponse<User>> {
    const url = getUrlWithId(UsersApi.UserById, id);

    return client.get(url);
  },

  updateUser(
    id: number,
    data: UserDetailsRequestBody,
  ): Promise<DictionaryResponse<User>> {
    const url = getUrlWithId(UsersApi.UserById, id);

    return client.put(url, data);
  },

  changeUserStatus(
    id: number,
    data: UserStatusRequestBody,
  ): Promise<DictionaryResponse<User>> {
    const url = getUrlWithId(UsersApi.ChangeUserStatus, id);

    return client.put(url, data);
  },
};
