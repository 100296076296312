import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/security',
    name: RouteName.Security,
    meta: { layout: Layout.Default },
    redirect: { name: RouteName.BackofficeLogs },
    children: [
      {
        path: 'backoffice-logs',
        name: RouteName.BackofficeLogs,
        component: () => import('@/views/ViewSecurity/ViewBackofficeLogs.vue'),
      },
    ],
  },
];
