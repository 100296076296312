import { AxiosRequestHeaders, AxiosResponse } from 'axios';

import { DictionaryResponse } from '@/app/types/api/config';
import {
  DeviceApi,
  DeviceVerificationResponse,
} from '@/app/types/api/resources/device';

import client from '@/app/api/client';

export default {
  verifyDevice(headers?: AxiosRequestHeaders): Promise<AxiosResponse<void>> {
    return client.post(DeviceApi.VerifyDevice, undefined, { headers });
  },

  checkDeviceVerification(): Promise<
    DictionaryResponse<DeviceVerificationResponse>
  > {
    return client.get(DeviceApi.CheckDeviceVerification);
  },
};
