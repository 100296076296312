import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/organizations',
    name: RouteName.Organizations,
    component: () => import('@/views/ViewOrganizations/ViewOrganizations.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/organizations/:id',
    name: RouteName.OrganizationDetails,
    redirect: { name: RouteName.OrganizationDetailsOverview },
    component: () =>
      import(
        '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetails.vue'
      ),
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'overview',
        name: RouteName.OrganizationDetailsOverview,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsOverview.vue'
          ),
      },
      {
        path: 'balances',
        name: RouteName.OrganizationDetailsBalances,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsBalances.vue'
          ),
      },
      {
        path: 'balance-statement',
        name: RouteName.OrganizationDetailsBalanceStatement,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsBalanceStatement.vue'
          ),
      },
      {
        path: 'verification',
        name: RouteName.OrganizationDetailsVerification,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsVerification.vue'
          ),
      },
      {
        path: 'merchant',
        name: RouteName.OrganizationDetailsMerchant,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsMerchant.vue'
          ),
      },
      {
        path: 'fees',
        name: RouteName.OrganizationDetailsFees,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsFees.vue'
          ),
      },
      {
        path: 'whitelist',
        name: RouteName.OrganizationDetailsWhitelist,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsWhitelist.vue'
          ),
      },
      {
        path: 'spreads',
        name: RouteName.OrganizationDetailsSpreads,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsSpreads.vue'
          ),
      },
      {
        path: 'crypto-transactions',
        name: RouteName.OrganizationDetailsCryptoTransactions,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsCryptoTransactions.vue'
          ),
      },
      {
        path: 'fiat-transactions',
        name: RouteName.OrganizationDetailsFiatTransactions,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsFiatTransactions.vue'
          ),
      },
      {
        path: 'allowed-assets',
        name: RouteName.OrganizationDetailsAllowedAssets,
        component: () =>
          import(
            '@/views/ViewOrganizations/ViewOrganizationDetails/ViewOrganizationDetailsAllowedAssets.vue'
          ),
      },
    ],
  },
];
