// To be used on app.use(Quasar, { ... })
import Notify from 'quasar/src/plugins/notify/Notify.js';import Screen from 'quasar/src/plugins/screen/Screen.js';;

const options = {
  config: {
    notify: {
      position: 'top',
      classes: 'app-notify',
    },
  },
  plugins: { Notify, Screen },
};

export default options as Partial<any>;
