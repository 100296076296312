import { Organization } from '@/app/types/api/resources/organizations';
import { Currency } from '@/app/types/app/currency';

export enum BanksApi {
  BankAccounts = '/backoffice/bank-accounts',
  InxyBankAccounts = '/backoffice/bank-accounts/inxy',
  BankAccountsById = '/backoffice/bank-accounts/:id',
  BankAccountVerify = '/backoffice/bank-accounts/:id/verify',
  BankAccountReject = '/backoffice/bank-accounts/:id/reject',
  AddFunds = '/backoffice/bank-accounts/:id/add-funds',
}

export enum BankAccountStatus {
  Processing = 'processing',
  Verified = 'verified',
  Rejected = 'rejected',
}

export interface BankAccount {
  id: number;
  customer_name: string;
  iban: string;
  bic: string;
  bank_name: string;
  bank_address: string;
  status: BankAccountStatus;
  pretty_name: string;
  created_at: string;
  updated_at: string;
  deleted_at: null | string;
  reject_reason: null | string;
  organization: Organization | null;
  currencies: Currency[];
}

export interface BankAccountRejectRequestBody {
  reason: string;
}

export interface BankAccountAddFundsRequestBody {
  currency_id: number;
  account_id: number;
  amount: string;
  fee_amount: string;
  processed_bank_account_id: number;
  note: string;
}
