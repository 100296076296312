import { Path } from '@intlify/core-base';

export enum Locales {
  En = 'en',
  EnUs = 'en-US',
  Ru = 'ru',
}

export type Translation = (
  key: Path | number,
  plural?: string | number | Record<string, string | number>,
) => string;
