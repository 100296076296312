export default {
  addresses: {
    addAddress: 'Add address',
    createAddress: 'Create address',
    restricted: {
      additionSuccess: 'Address was successfully added to restricted.',
      removalSuccess: 'Address was successfully removed from restricted.',
    },
    active: {
      creationSuccess: 'Address was successfully created.',
      disableSuccess: 'Address was successfully disabled.',
    },
    emptyMessageText: 'No addresses found.',
  },
  kyt: {
    emptyMessageText: 'No KYT settings found.',
    updateSuccess: 'KYT settings were updated successfully.',
  },
  restrictedIps: {
    addIp: 'Add IP',
    emptyMessageText: 'No IPs found.',
    additionSuccess: 'IP was successfully added to restricted.',
    removalSuccess: 'IP was successfully removed from restricted.',
  },
  referral: {
    partners: {
      updateSuccess: 'Referral partner was updated successfully.',
      createSuccess: 'Referral partner was created successfully.',
      deleteSuccess: 'Referral partner was deleted successfully.',
      createPartner: 'Create partner',
      emptyMessageText: 'No referral partners found.',
      details: {
        title: 'Referral partner details',
        partnerOrgs: 'Partner organizations',
      },
    },
  },
  allowedAssets: {
    title: 'Allowed assets template',
    updateAllowedAssetsSuccess: 'Allowed assets template updated successfully',
  },
};
