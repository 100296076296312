import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/payment-audit-history',
    name: RouteName.PaymentAuditHistory,
    component: () =>
      import('@/views/ViewPaymentAuditHistory/ViewPaymentAuditHistory.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/payment-audit-history/:id',
    name: RouteName.PaymentAuditHistoryDetails,
    component: () =>
      import('@/views/ViewPaymentAuditHistory/ViewPaymentAuditDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
