import {
  CoinType,
  CryptoCurrencyBlockchain,
  CryptoCurrencyCoinType,
} from '@/app/types/app/currency';

export default class CoinTypeName implements CoinType {
  readonly #blockchain: CryptoCurrencyBlockchain;
  readonly #coinType: CryptoCurrencyCoinType | string;

  constructor(
    blockchain: CryptoCurrencyBlockchain,
    coinType: CryptoCurrencyCoinType | string,
  ) {
    this.#blockchain = blockchain;
    this.#coinType = coinType;
  }

  get custom(): CryptoCurrencyCoinType | string {
    switch (this.#blockchain) {
      case CryptoCurrencyBlockchain.Polygon:
        return '';
      default:
        return this.#coinType;
    }
  }

  get native(): CryptoCurrencyCoinType | string {
    switch (this.#blockchain) {
      case CryptoCurrencyBlockchain.Ethereum:
        return CryptoCurrencyCoinType.ERC20;
      default:
        return '';
    }
  }

  get isNative(): boolean {
    return this.#coinType === CryptoCurrencyCoinType.Native;
  }

  get #value(): CryptoCurrencyCoinType | string {
    return this.isNative ? this.native : this.custom;
  }

  get value(): CryptoCurrencyCoinType | string {
    return this.#value.toUpperCase();
  }

  get valueWrapped(): string {
    return this.value === '' ? this.value : `(${this.value})`;
  }
}
