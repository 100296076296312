import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/bank-accounts',
    name: RouteName.BankAccounts,
    component: () => import('@/views/ViewBankAccounts/ViewBankAccounts.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/bank-accounts/:id',
    name: RouteName.BankAccountDetails,
    component: () =>
      import('@/views/ViewBankAccounts/ViewBankAccountDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
