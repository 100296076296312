import { AxiosResponse } from 'axios';

import {
  AuthApi,
  AuthFormData,
  AuthResponse,
} from '@/app/types/api/resources/auth';

import client from '@/app/api/client';

export default {
  login(data: AuthFormData): Promise<AxiosResponse<AuthResponse>> {
    return client.post(AuthApi.Login, data);
  },
};
