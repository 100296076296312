import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/tools',
    name: RouteName.Tools,
    meta: { layout: Layout.Default },
    redirect: { name: RouteName.MatchTransaction },
    children: [
      {
        path: 'match-transaction',
        name: RouteName.MatchTransaction,
        redirect: { name: RouteName.MatchTransactionSession },
        children: [
          {
            path: 'session',
            name: RouteName.MatchTransactionSession,
            component: () =>
              import(
                '@/views/ViewTools/match-transaction/ViewMatchSession.vue'
              ),
          },
          {
            path: 'account',
            name: RouteName.MatchTransactionAccount,
            component: () =>
              import(
                '@/views/ViewTools/match-transaction/ViewMatchAccount.vue'
              ),
          },
        ],
      },
      {
        path: 'balance-control',
        name: RouteName.BalanceControl,
        component: () => import('@/views/ViewTools/ViewBalanceControl.vue'),
      },
      {
        path: 'balance-overview',
        name: RouteName.BalanceOverview,
        component: () => import('@/views/ViewTools/ViewBalanceOverview.vue'),
      },
      {
        path: 'add-transaction',
        name: RouteName.AddTransaction,
        component: () => import('@/views/ViewTools/ViewAddTransaction.vue'),
      },
      {
        path: 'pending-withdraw-csv',
        name: RouteName.PendingWithdrawCsv,
        component: () => import('@/views/ViewTools/ViewPendingWithdraw.vue'),
      },
      {
        path: 'payment-audit',
        name: RouteName.PaymentAudit,
        component: () => import('@/views/ViewTools/ViewPaymentAudit.vue'),
      },
    ],
  },
];
