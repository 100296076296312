import { MutationTree } from 'vuex';

import { AccessMutations, AccessState } from '@/app/types/store/modules/access';

const state = (): AccessState => ({
  isDeviceVerified: null,
});

const mutations: MutationTree<AccessState> & AccessMutations = {
  setDeviceVerification(state, isVerified) {
    state.isDeviceVerified = isVerified;
  },
};

export default {
  namespaced: true,

  state,
  getters: {},
  mutations,
};
