import { QNotifyCreateOptions } from 'quasar/dist/types/index';

import { HttpStatus, InternalStatus } from '@/app/types/api/config';

export interface ClientResponseErrorParams extends Error {
  messages: string[];
  status?: number;
  errors: Record<string, any> | null;
  notify: QNotifyCreateOptions | null;
  isTwoFactorAuth: boolean;
}

export default class ClientResponseError extends Error {
  messages: string[];
  notify: QNotifyCreateOptions | null;
  name: string;
  status?: number;
  errors: Record<string, any> | null;
  isTwoFactorAuth: boolean;

  constructor({
    message,
    messages,
    status,
    errors,
    notify,
    isTwoFactorAuth,
  }: ClientResponseErrorParams) {
    super(message);

    this.name = 'Server response error';
    this.messages = messages;
    this.status = status;
    this.errors = errors;
    this.notify = notify;
    this.isTwoFactorAuth = isTwoFactorAuth;
  }

  static createSimpleError(
    name: string,
    status?: HttpStatus | InternalStatus,
    message?: string,
  ) {
    return new ClientResponseError({
      message: message ?? name,
      status,
      notify: { type: 'negative', message: message ?? name },
      errors: [],
      name,
      messages: [],
      isTwoFactorAuth: false,
    });
  }
}
