<template>
  <div class="empty-layout">
    <AppLogoHeader class="empty-layout__header" />

    <slot />
  </div>
</template>

<script lang="ts">
import { Layout } from '@/app/types/app/routes';

export default { name: Layout.Empty };
</script>

<script setup lang="ts">
import AppLogoHeader from '@C/layout/AppLogoHeader.vue';
</script>

<style scoped lang="scss">
.empty-layout {
  min-height: 100vh;

  &__header {
    height: 72px;

    @media (width >= 1024px) {
      padding: 0 32px;
    }
  }
}
</style>
