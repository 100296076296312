import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/accounts',
    name: RouteName.OrgAccounts,
    component: () => import('@/views/ViewOrgAccounts/ViewOrgAccounts.vue'),
    meta: { layout: Layout.Default },
  },
];
