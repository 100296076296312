import { StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import device from '@/app/api/resources/device';

export default class {
  checkDeviceVerification() {
    return device.checkDeviceVerification().then(({ data }) => {
      Store.commit(StoreCommit.SetDeviceVerification, data.data.verified);

      return Promise.resolve();
    });
  }
}
