export default {
  match: {
    labels: {
      transactionDetails: 'Transaction details',
      sessionDetails: 'Session details',
      organizationDetails: 'Organization details',
      transactionId: 'Transaction TxID',
      sessionHash: 'Session hash',
      sessionDate: 'Session date',
      intentCurrency: 'Payment intent cryptocurrency',
      intentAmount: 'Payment intent amount',
      sessionMerchant: 'Session merchant',
      sessionCustomer: 'Session customer',
      sessionWallet: 'Session wallet',
      transactionDate: 'Transaction date',
      transactionCurrency: 'Transaction cryptocurrency',
      transactionAmount: 'Transaction amount',
      transactionWallet: 'Transaction wallet',
      transactionStatus: 'Transaction status',
    },
    matchButton: 'Match transaction',
    matchSuccess: 'Transaction was successfully matched',
    changeCryptoSuccess: 'Session cryptocurrency was successfully changed',
    addTransactionSuccess: 'Transaction was successfully added',
    addressesHint: 'Use commas to add multiple addresses',
  },
  balanceControl: {
    transferSuccess: 'Balance transfer completed successfully',
    transactionHash: 'Transaction hash',
  },
  pendingWithdrawCsv: {
    downloadCsv: 'Download pending transactions',
    downloadBtcCsv: 'Download pending BTC transactions',
    uploadCsv: 'Upload pending transactions',
    uploadSuccess: 'File was successfully uploaded',
  },
  paymentAudit: {
    resultsTitle: 'Payment audit result',
  },
};
