<template>
  <QLayout view="lhh lpR lFf default-layout">
    <QHeader bordered class="default-layout__header">
      <AppToolbar class="default-layout__toolbar" @toggle="toggleLeftDrawer" />
    </QHeader>

    <QDrawer
      v-model="leftDrawerOpen"
      show-if-above
      side="left"
      class="default-layout__aside"
    >
      <AppLogoHeader />

      <AppNavigation />
    </QDrawer>

    <QPageContainer>
      <QPage padding class="default-layout__content">
        <slot />
      </QPage>
    </QPageContainer>
  </QLayout>
</template>

<script lang="ts">
import { Layout } from '@/app/types/app/routes';

export default { name: Layout.Default };
</script>

<script setup lang="ts">
import { ref } from 'vue';

import AppLogoHeader from '@C/layout/AppLogoHeader.vue';
import AppNavigation from '@C/layout/AppNavigation.vue';
import AppToolbar from '@C/layout/AppToolbar/AppToolbar.vue';

const leftDrawerOpen = ref(false);

function toggleLeftDrawer() {
  leftDrawerOpen.value = !leftDrawerOpen.value;
}
</script>

<style lang="scss">
.default-layout {
  &__header,
  &__aside {
    color: var(--clr-text-primary);
    background: var(--clr-background-secondary);
  }

  &__aside {
    border-right: 1px solid var(--clr-background-primary);
  }

  &__toolbar {
    padding: 0 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;

    & > * {
      position: relative;
      flex-grow: 1;
      background-color: var(--clr-background-card);
      border-radius: 8px;
      box-shadow: var(--box-shadow-base);
    }
  }
}
</style>
