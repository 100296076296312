import Screen from 'quasar/src/plugins/screen/Screen.js';;

export default {
  setScreen() {
    Screen.setSizes({
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    });
  },
  init() {
    this.setScreen();
  },
};
