export enum ModelEvent {
  UpdateModelValue = 'update:modelValue',
  UpdateActiveTab = 'update:activeTab',
  UpdateWhitelist = 'update:whitelist',
}

export enum AppEvent {
  Click = 'click',
  Submit = 'submit',
  Show = 'show',
  Select = 'select',
  Update = 'update',
  Proceed = 'proceed',
  Toggle = 'toggle',
  Clear = 'clear',
  Edit = 'edit',
  Add = 'add',
  SetAll = 'set-all',
  Delete = 'delete',
  Cancel = 'cancel',
  ChangeCrypto = 'change-crypto',
  VerifySuccess = 'verify-success',
  Loaded = 'loaded',
}

export enum TableEvent {
  RowClick = 'row-click',
  UpdateFeeAmount = 'update-fee-amount',
  UpdateFeePercent = 'update-fee-percent',
  UpdateSpreadPercent = 'update-spread-percent',
}
