import { AccessState } from '@/app/types/store/modules/access';
import { AuthState } from '@/app/types/store/modules/auth';
import { DictionariesState } from '@/app/types/store/modules/dictionaries';

export interface Store {
  auth: AuthState;
  dictionaries: DictionariesState;
  access: AccessState;
}

export enum StoreCommit {
  // access
  SetDeviceVerification = 'access/setDeviceVerification',
  // users
  SetUser = 'auth/setUser',
  // dictionaries
  SetCurrencies = 'dictionaries/setCurrencies',
  SetCryptoCurrencies = 'dictionaries/setCryptoCurrencies',
  SetJurisdictions = 'dictionaries/setJurisdictions',
  SetInxyBankAccounts = 'dictionaries/setInxyBankAccounts',
  SetKytProviders = 'dictionaries/setKytProviders',
}

export enum StoreGetter {
  HasUser = 'auth/hasUser',
  IsEmailVerified = 'auth/isEmailVerified',
  IsUserAdmin = 'auth/isUserAdmin',
  UserId = 'auth/userId',
  UserEmail = 'auth/userEmail',
  ExtendedCrypto = 'dictionaries/extendedCryptoCurrencies',
  GetNativeCrypto = 'dictionaries/getNativeCrypto',
}
