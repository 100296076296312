import { createRouter, createWebHistory } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import ClientResponseError from '@/app/api/factories/ClientResponseError';
import env from '@/app/env';

import device from '@/router/middleware/global/device.global';
import dictionaries from '@/router/middleware/global/dictionaries.global';
import emailVerification from '@/router/middleware/global/emailVerification.global';
import user from '@/router/middleware/global/user.global';
import { resolveErrorRoute } from '@/router/resources/errorRouteResolver';
import routes from '@/router/resources/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  if (to.name === RouteName.Login) {
    return;
  }

  try {
    await user()
      .then(emailVerification)
      .then(() => device(to, from))
      .then(dictionaries);

    return;
  } catch (routeError) {
    if (env.buildMode === 'development') {
      console.error(routeError);
    }

    const error = routeError as Error | ClientResponseError;

    return resolveErrorRoute(error, to.name as RouteName);
  }
});

export default router;
