export enum RouteName {
  Home = 'home',
  Login = 'login',
  Dashboard = 'dashboard',
  NotFound = 'not-found',
  DeviceVerification = 'device-verification',
  Users = 'users',
  User = 'user',
  OrgAccounts = 'org-accounts',
  PaymentAuditHistory = 'payment-audit-history',
  PaymentAuditHistoryDetails = 'payment-audit-history-details',
  // Organizations
  Organizations = 'organizations',
  OrganizationDetails = 'organization-details',
  OrganizationDetailsOverview = 'organization-details-overview',
  OrganizationDetailsCryptoTransactions = 'organization-details-crypto-transactions',
  OrganizationDetailsFiatTransactions = 'organization-details-fiat-transactions',
  OrganizationDetailsBalances = 'organization-details-balances',
  OrganizationDetailsBalanceStatement = 'organization-details-balance-statement',
  OrganizationDetailsMerchant = 'organization-details-merchant',
  OrganizationDetailsVerification = 'organization-details-verification',
  OrganizationDetailsFees = 'organization-details-fees',
  OrganizationDetailsWhitelist = 'organization-details-whitelist',
  OrganizationDetailsSpreads = 'organization-details-spreads',
  OrganizationDetailsAllowedAssets = 'organization-details-allowed-assets',
  // Withdraw Requests
  WithdrawRequests = 'withdraw-requests',
  CryptoWithdrawRequests = 'crypto-withdraw-requests',
  FiatWithdrawRequests = 'fiat-withdraw-requests',
  CryptoWithdrawRequest = 'crypto-withdraw-request',
  FiatWithdrawRequest = 'fiat-withdraw-request',
  // Exchange Requests
  ExchangeRequests = 'exchange-requests',
  ExchangeRequest = 'exchange-request',
  // Transactions
  Transactions = 'transactions',
  CryptoTransactions = 'crypto-transactions',
  FiatTransactions = 'fiat-transactions',
  CryptoTransaction = 'crypto-transaction',
  PendingCryptoTransaction = 'pending-crypto-transaction',
  // Banks
  BankAccounts = 'bank-accounts',
  BankAccountDetails = 'bank-account-details',
  // Paygate Sessions
  PaygateSessions = 'paygate-sessions',
  PaygateSessionsDetails = 'paygate-sessions-details',
  // Settings
  Settings = 'settings',
  KytRotator = 'kyt-rotator',
  RestrictedAddresses = 'restricted-addresses',
  ActiveAddresses = 'active-addresses',
  RestrictedIps = 'restricted-ips',
  AllowedAssets = 'allowed-assets',
  // Referral
  Referral = 'referral',
  ReferralPartners = 'referral-partners',
  ReferralPartnerDetails = 'referral-partner-details',
  // Documents
  Documents = 'documents',
  FundingInstructions = 'funding-instructions',
  TransactionReports = 'transaction-reports',
  Invoices = 'invoices',
  // AML
  AML = 'aml',
  AmlDetails = 'aml-details',
  AmlPendingReview = 'aml-pending-review',
  AmlOnHold = 'aml-on-hold',
  // Billing
  RefundOrders = 'refund-orders',
  RefundOrdersDetails = 'refund-orders-details',
  // Security
  Security = 'security',
  BackofficeLogs = 'backoffice-logs',
  // Tools
  Tools = 'tools',
  MatchTransaction = 'match-transaction',
  MatchTransactionSession = 'match-transaction-session',
  MatchTransactionAccount = 'match-transaction-account',
  BalanceControl = 'balance-control',
  BalanceOverview = 'balance-overview',
  AddTransaction = 'add-transaction',
  PendingWithdrawCsv = 'pending-withdraw-csv',
  PaymentAudit = 'payment-audit',
}

export enum Layout {
  Default = 'LayoutDefault',
  Empty = 'LayoutEmpty',
}
