import { BankAccount } from '@/app/types/api/resources/banks';
import { Organization } from '@/app/types/api/resources/organizations';

export enum UsersApi {
  MyUser = '/backoffice/users/me',
  Users = '/backoffice/users',
  UserById = '/backoffice/users/:id',
  ChangeUserStatus = '/backoffice/users/:id/change-status',
}

export interface User {
  id: number;
  email: string;
  username: string;
  account_type: null | UserAccountType;
  status: UserStatus;
  role: UserRole;
  roles: UserRoleDetails[];
  permissions: string[]; // todo later
  registration_type: null | UserRegType;
  company: null | UserCompany;
  first_name: string;
  last_name: string;
  bank_accounts: BankAccount[];
  email_verified_at: string;
  analytics_id: string;
  created_at: string;
  last_visit: null | string;
  organization: Organization;
}

export interface UserRoleDetails {
  id: number;
  name: UserRole;
  guard_name: UserRoleGuard;
  created_at: string;
  updated_at: string;
}

export enum UserRole {
  Admin = 'admin',
  Customer = 'customer',
}

export enum UserRoleGuard {
  Backoffice = 'backoffice',
  // todo later
}

export enum UserRegType {
  Auth = 'auth',
  Gateway = 'payment_gateway',
}

export enum UserAccountType {
  Individual = 'individual',
  Company = 'company',
}

export enum UserStatus {
  Active = 'active',
  Blocked = 'blocked',
}

export interface UserCompany {
  name: string;
}

export interface UserDetailsRequestBody {
  username: string;
  email: string;
  role: UserRole;
  account_type: UserAccountType;
}

export interface UserStatusRequestBody {
  status: UserStatus;
}
