import { Layout, RouteName } from '@/app/types/app/routes';

import authGuard from '@/router/guards/authGuard';
import deviceVerificationGuard from '@/router/guards/deviceVerificationGuard';
import redirectFromGuard from '@/router/guards/redirectFromGuard';

export default [
  // auth
  {
    path: '/auth',
    redirect: { name: RouteName.Login },
    meta: { layout: Layout.Empty },
    children: [
      {
        path: 'login',
        name: RouteName.Login,
        component: () => import('@/views/ViewLogin.vue'),
        beforeEnter: [redirectFromGuard, authGuard],
      },
    ],
  },
  // device
  {
    path: '/device',
    name: RouteName.DeviceVerification,
    component: () => import('@/views/ViewDeviceVerification.vue'),
    meta: { layout: Layout.Empty },
    beforeEnter: deviceVerificationGuard,
  },
  // 404
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
  {
    path: '/404',
    name: RouteName.NotFound,
    component: () => import('@/views/ViewNotFound.vue'),
    meta: { layout: Layout.Empty },
  },
];
