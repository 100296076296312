export default {
  updateVerificationSuccess:
    'Organization verification status changed successfully',
  updateWhitelistSuccess: 'Organization whitelist updated successfully',
  updateAllowedAssetsSuccess:
    'Organization allowed assets updated successfully',
  emptyMessageText: 'No organizations found.\n\nTry other filter settings.',
  fees: {
    maxOperationHint: 'Leave blank if there is no maximum applicable amount',
    noMerchant: "The organization doesn't have a merchant yet",
    noPayer: 'The organization is not a payer yet',
    noCustomFees: 'No custom fees have been created yet',
    updateFeesSuccess: 'Organization fees updated successfully',
  },
  spreads: {
    updateSpreadsSuccess: 'Organization spreads updated successfully',
    emptyMessageText: 'No spread settings found for this organization',
  },
  details: {
    loadBalance: 'Load balance',
    fiatBalances: 'Fiat balances',
    cryptoBalances: 'Crypto balances',
    balanceStatement: 'Balance statement',
    statementTransactions: 'Statement transactions',
    fees: 'Fees',
    merchant: 'Merchant details',
    verification: 'Verification details',
    bypassVerification: 'Bypass KYB',
    whitelist: 'Withdrawal whitelist',
    allowedAssets: 'Allowed assets',
    spreads: 'Spreads',
  },
};
