import Store from '@/store';
import DictionariesStoreService from '@/store/services/DictionariesStoreService';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    const hasDictionaries = !!state.dictionaries.cryptocurrencies.length;

    if (hasDictionaries) {
      return resolve();
    }

    const hasUser = !!state.auth.user;

    if (!hasUser) {
      return resolve();
    }

    const dictService = new DictionariesStoreService();

    return dictService
      .fetchDictionaries()
      .then(() => resolve())
      .catch(() => reject());
  });
}
