export default {
  confirmationTitle: 'Confirmation',
  changeCrypto: {
    description:
      'Do you really want to change cryptocurrency to {cryptocurrency} ?',
  },
  matchSession: {
    description:
      'Do you really want to match the transaction with this session?',
  },
  matchAccount: {
    description:
      'Do you really want the transaction to be credited to the account balance?',
  },
  changeUserStatus: {
    description: 'Are you sure you want to change user status?',
  },
  changeUserDetails: {
    description: 'Are you sure you want to change user details?',
  },
  updateWhitelist: {
    description:
      'Are you sure you want to update organization withdrawal whitelist?',
  },
  twoStepVerification: {
    title: 'Two-step verification',
    description: "Enter the authentication code we've sent to",
    below: 'below',
    resendCode: 'Resend code',
    requestCode: 'Request another code in ',
    checkEmailMessage: 'Confirmation code was sent to your email',
  },
  verificationManualApproval: {
    approve: 'Are you sure you want to approve organization verification?',
    decline: 'Are you sure you want to decline organization verification?',
  },
  exchangeFeeCreate: {
    title: 'Create exchange fee',
    updateTitle: 'Update exchange fee',
    massUpdateTitle: 'Set all exchange fees',
  },
  fiatFeeCreate: {
    title: 'Create fiat fee',
    updateTitle: 'Update fiat fee',
  },
  deleteCustomFees: {
    description: 'Are you sure you want to delete all custom fees?',
    descriptionSingle: 'Are you sure you want to delete this custom fee?',
  },
  addTransactionTxid: {
    title: 'Add transaction TxID',
  },
  bankAccountApproval: {
    approve: 'Are you sure you want to approve this bank account?',
    reject: 'Reject bank account',
  },
  addMissingTransaction: {
    description: 'Are you sure you want to add this transaction?',
  },
  removeRestrictedAddress: {
    description:
      'Are you sure you want to remove this address from restricted?',
  },
  addRestrictedAddress: {
    title: 'Add address to restricted',
  },
  rejectModal: {
    rejectLabel: 'Reject reason',
  },
  fiatRequestApproval: {
    approve: 'Approve fiat withdraw request',
    reject: 'Reject fiat withdraw request',
  },
  bankAccountAddFunds: {
    title: 'Add funds to bank account #{bankAccountId}',
  },
  cancelCryptoTransaction: {
    description:
      'You are going to finish this transaction.\n Money will be refunded to account balance.',
  },
  cancelFiatTransaction: {
    description: 'Are you sure you want to reject transaction #{id}?',
  },
  changeTransactionAddress: {
    title: 'Change transaction address',
  },
  transactionAmlReversal: {
    description:
      'You are going to make an AML reversal for this transaction.\n Money will be deducted from customer’s balance.',
  },
  createKytSettings: {
    title: 'Setup KYT Rotator rule',
  },
  removeKytSettings: {
    description: 'Are you sure you want to remove this KYT setting?',
  },
  bypassVerification: {
    title: 'Bypass verification',
  },
  undefinedTransactionStatusChange: {
    description:
      'Are you sure you want to change transaction status to "{status}"',
  },
  referralPartner: {
    createTitle: 'Create new referral partner',
    editTitle: 'Edit referral partner',
  },
  deleteReferralPartner: {
    description: 'Are you sure you want to delete this referral partner?',
  },
  disableWatchedAddress: {
    description: 'Are you sure you want to disable this address?',
  },
  createAddress: {
    title: 'Create new address',
  },
  deleteOrgSpread: {
    description: 'Are you sure you want to delete this spread setting?',
    descriptionAll: 'Are you sure you want to delete all spread settings?',
  },
  createOrgSpread: {
    title: 'Create spread setting',
    massTitle: 'Set all spread settings',
  },
  removeRestrictedIp: {
    description: 'Are you sure you want to remove this IP from restricted?',
  },
  addRestrictedIp: {
    title: 'Add IP to restricted',
  },
  amlStatusUpdate: {
    description: 'Are you sure you want to change AML status to "{status}"?',
  },
  refundOrderComplete: {
    description: 'Are you sure you want to complete this return request?',
  },
  updateOrgAllowedAssets: {
    description: 'Are you sure you want to update organization allowed assets?',
  },
  updateAllowedAssetsTemplate: {
    description: 'Are you sure you want to update allowed assets template?',
  },
};
