import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { PaginatedResponse } from '@/app/types/app/pagination';

export interface RequestInterceptor {
  onSuccess: (
    requestConfig: InternalAxiosRequestConfig,
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  onError: (error: any) => any;
}

export interface ResponseInterceptor {
  onSuccess: (
    response: AxiosResponse,
  ) => AxiosResponse | Promise<AxiosResponse>;
  onError: (error: any) => any;
}

export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  ServiceUnavailable = 503,
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
}

export enum InternalStatus {
  DeviceNotVerified = 4004,
}

export type DictionaryResponse<T> = AxiosResponse<{ data: T }>;

export type AxiosPaginatedResponse<T> = AxiosResponse<PaginatedResponse<T>>;

export interface ErrorResponse {
  message: string;
  errors?: Record<string, string[]>;
}

export enum TwoFactorAuth {
  Header = 'X-INXY-Payments-2FA-Code',
  ConfirmationMessage = 'need two factor auth',
}

export enum ResponseHeaders {
  ContentDisposition = 'content-disposition',
}
