import { AxiosError } from 'axios';

import {
  ErrorResponse,
  HttpStatus,
  RequestInterceptor,
  ResponseInterceptor,
} from '@/app/types/api/config';
import { RouteName } from '@/app/types/app/routes';

import cookies from '@/plugins/cookies';
import i18n from '@/plugins/i18n';

import ClientResponseError from '@/app/api/factories/ClientResponseError';
import ErrorResponseInterceptorNotify, {
  InterceptorNotifyName,
} from '@/app/api/factories/ErrorResponseInterceptorNotify';
import env from '@/app/env';

import router from '@/router';

const getToken = () => {
  const token = cookies.get(env.cookieName);

  return token ? 'Bearer ' + token : '';
};

function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message =
    error.response?.data?.message ??
    i18n.global.t('errors.standardServerError');
  const errors = error.response?.data?.errors;

  switch (error?.response?.status) {
    case HttpStatus.Unauthorized:
      cookies.remove(env.cookieName, '/', env.host);

      router.push({ name: RouteName.Login });

      return [message];
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message];
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')];
    default:
      return [message];
  }
}

const requestInterceptors: RequestInterceptor = {
  onSuccess(config) {
    const token = config?.headers?.Authorization;
    const isTokenExpired = token !== getToken();

    if (config.headers && (!token || isTokenExpired)) {
      config.headers['Authorization'] = getToken();
    }

    return config;
  },
  onError(error) {
    return Promise.reject(error);
  },
};

const responseInterceptor: ResponseInterceptor = {
  onSuccess(response) {
    return response;
  },
  onError(error: AxiosError<ErrorResponse>): Promise<ClientResponseError> {
    const responseStatus: HttpStatus | undefined = error.response?.status;

    const messages = getErrorMessages(error);

    const notify = new ErrorResponseInterceptorNotify(error, messages);

    notify.create();

    const isTwoFactorAuth =
      notify.notifyName === InterceptorNotifyName.TwoFactorAuthorization;

    return Promise.reject(
      new ClientResponseError({
        name: error.name,
        message: error.message,
        status: responseStatus,
        errors: error.response?.data?.errors ?? null,
        messages,
        notify: notify.options,
        isTwoFactorAuth,
      }),
    );
  },
};

export default {
  baseURL: env.apiURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
  },
  timeout: 20000,
  requestInterceptors,
  responseInterceptor,
};
