import { AxiosResponse } from 'axios';

import { AuthFormData, AuthResponse } from '@/app/types/api/resources/auth';
import { StoreCommit } from '@/app/types/store/store';

import cookies from '@/plugins/cookies';
import Store from '@/store';

import authApi from '@/app/api/resources/auth';
import usersApi from '@/app/api/resources/users';
import env from '@/app/env';

export default class {
  fetchMe() {
    return usersApi
      .fetchMe()
      .then(({ data }) => Store.commit(StoreCommit.SetUser, data.data));
  }

  #setAuthToken(data: AuthResponse) {
    Store.commit(StoreCommit.SetUser, data.user);

    cookies.set(env.cookieName, data.access_token, '7d', '/', env.host);
  }

  login(formData: AuthFormData) {
    return authApi
      .login(formData)
      .then(({ data }: AxiosResponse<AuthResponse>) => {
        this.#setAuthToken(data);

        return data;
      });
  }
}
