import 'quasar/dist/quasar.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@/assets/styles/entry.scss';

import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';

import quasarUserOptions from '@/app/quasar/quasar-user-options';
import QuasarDefaultSettings from '@/app/quasar/QuasarDefaultSettings';

import App from './App.vue';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createMetaManager())
  .mount('#app');

QuasarDefaultSettings.init();
