import { User } from '@/app/types/api/resources/users';

export enum AuthApi {
  Login = '/backoffice/login',
}

export interface AuthFormData {
  email: string;
  password: string;
}

export interface AuthResponse {
  access_token: string;
  user: User;
}
