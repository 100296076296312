import {
  AxiosPaginatedResponse,
  DictionaryResponse,
} from '@/app/types/api/config';
import {
  BankAccount,
  BankAccountAddFundsRequestBody,
  BankAccountRejectRequestBody,
  BanksApi,
} from '@/app/types/api/resources/banks';
import { RequestParams } from '@/app/types/app/pagination';

import client from '@/app/api/client';
import getUrlWithId from '@/app/helpers/getUrlWithId';

export default {
  fetchBankAccounts(
    params: RequestParams,
  ): Promise<AxiosPaginatedResponse<BankAccount[]>> {
    return client.get(BanksApi.BankAccounts, { params });
  },

  fetchInxyBankAccounts(): Promise<DictionaryResponse<BankAccount[]>> {
    return client.get(BanksApi.InxyBankAccounts);
  },

  fetchBankAccountDetails(
    id: number,
  ): Promise<DictionaryResponse<BankAccount>> {
    const url = getUrlWithId(BanksApi.BankAccountsById, id);

    return client.get(url);
  },

  verifyBankAccount(id: number): Promise<DictionaryResponse<BankAccount>> {
    const url = getUrlWithId(BanksApi.BankAccountVerify, id);

    return client.post(url);
  },

  rejectBankAccount(
    id: number,
    data: BankAccountRejectRequestBody,
  ): Promise<DictionaryResponse<BankAccount>> {
    const url = getUrlWithId(BanksApi.BankAccountReject, id);

    return client.post(url, data);
  },

  addFundsToBankAccount(
    id: number,
    data: BankAccountAddFundsRequestBody,
  ): Promise<DictionaryResponse<BankAccount>> {
    const url = getUrlWithId(BanksApi.AddFunds, id);

    return client.post(url, data);
  },
};
