import { HttpStatus } from '@/app/types/api/config';
import { StoreGetter } from '@/app/types/store/store';

import cookies from '@/plugins/cookies';
import Store from '@/store';
import AuthStoreService from '@/store/services/AuthStoreService';

import ClientResponseError from '@/app/api/factories/ClientResponseError';
import env from '@/app/env';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const token = cookies.get(env.cookieName);

    if (token === null) {
      return reject(
        ClientResponseError.createSimpleError(
          'User not authenticated',
          HttpStatus.Unauthorized,
        ),
      );
    }

    if (Store.getters[StoreGetter.HasUser]) {
      return resolve();
    }

    const authService = new AuthStoreService();

    return authService
      .fetchMe()
      .then(resolve)
      .catch(() => {
        cookies.remove(env.cookieName, '/', env.host);

        return reject(
          ClientResponseError.createSimpleError(
            'User not authenticated',
            HttpStatus.Unauthorized,
          ),
        );
      });
  });
}
