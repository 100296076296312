export default {
  pages: {
    dashboard: 'Dashboard',
    login: 'Login',
    deviceVerification: 'Device verification',
    users: 'Users',
    organizations: 'Organizations',
    withdrawRequests: 'Withdraw requests',
    cryptoWithdrawRequests: 'Crypto requests',
    fiatWithdrawRequests: 'Fiat requests',
    transactions: 'Transactions',
    cryptoTransactions: 'Crypto transactions',
    pendingCryptoTransactions: 'Pending crypto transactions',
    fiatTransactions: 'Fiat transactions',
    exchangeRequests: 'Exchange requests',
    bankAccounts: 'Bank accounts',
    paygateSessions: 'Paygate sessions',
    orgAccounts: 'Accounts',
    paymentAuditHistory: 'Payment audit history',
    // settings
    settings: 'Settings',
    kytRotator: 'KYT Rotator',
    restrictedAddresses: 'Restricted addresses',
    restrictedIps: 'Restricted IPs',
    referralPartners: 'Referral partners',
    activeAddresses: 'Active addresses',
    allowedAssets: 'Allowed assets',
    // documents
    documents: 'Documents',
    transactionReports: 'Transaction reports',
    fundingInstructions: 'Funding instructions',
    invoices: 'Invoices',
    // aml
    aml: 'AML',
    amlPendingReview: 'AML Pending review',
    amlOnHold: 'AML On hold',
    amlReturnRequests: 'Return requests',
    // tools
    tools: 'Tools',
    matchTransactionSession: 'Match transaction to session',
    matchTransactionAccount: 'Match transaction to account',
    balanceControl: 'Balance control',
    balanceOverview: 'Balance overview',
    addTransaction: 'Add transaction',
    pendingWithdrawCsv: 'Handle pending withdrawal CSV',
    paymentAudit: 'Payment audit',
    // security
    security: 'Security',
    backofficeLogs: 'Backoffice logs',
  },
  messages: {
    copied: 'Copied!',
    blockchain: 'Blockchain',
    internal: 'Internal',
    optional: 'Optional',
    tryOtherFilter: 'Try other filter settings.',
    showDefaultOnly: 'Show default only',
    forever: 'Forever',
  },
  buttons: {
    logOut: 'Log out',
    submit: 'Submit',
    complete: 'Complete',
    confirm: 'Confirm',
    cancel: 'Cancel',
    clear: 'Clear',
    okay: 'Okay',
    continue: 'Continue',
    transfer: 'Transfer',
    clearAll: 'Clear all',
    saveChanges: 'Save changes',
    showRawData: 'Show raw',
    approve: 'Approve',
    decline: 'Decline',
    reject: 'Reject',
    addFilter: 'Add filter',
    addNew: 'Add new',
    deleteAll: 'Delete all',
    setAll: 'Set all',
    basic: 'Basic {name}',
    extended: 'Extended {name}',
    addFunds: 'Add funds',
    upload: 'Upload',
    finish: 'Finish',
    download: 'Download',
    downloadAll: 'Download All',
  },
  labels: {
    id: 'ID',
    uid: 'UID',
    email: 'Email',
    username: 'Username',
    user: 'User',
    owner: 'Owner',
    ownerStatus: 'Owner status',
    userId: 'User ID',
    requestId: 'Request ID',
    firstName: 'First name',
    lastName: 'Last name',
    type: 'Type',
    operationType: 'Operation type',
    regType: 'Registration type',
    role: 'Role',
    accountType: 'Account type',
    defaultCurrency: 'Default currency',
    private: 'Private',
    business: 'Business',
    name: 'Name',
    label: 'Label',
    kyc: 'KYC/KYB',
    status: 'Status',
    result: 'Result',
    analyticsId: 'Analytics ID',
    date: 'Date',
    createdDate: 'Created date',
    updatedDate: 'Updated date',
    deletedDate: 'Deleted date',
    expiredDate: 'Expired date',
    signupDate: 'Signup date',
    confirmDate: 'Confirmation date',
    completeDate: 'Completed date',
    onHoldDate: 'On hold date',
    onHoldUntil: 'On hold until',
    tradeDate: 'Trade date',
    transactionDate: 'Transaction date',
    lastVisitDate: 'Last visit',
    period: 'Period',
    search: 'Search',
    currency: 'Currency',
    requestCurrency: 'Request currency',
    cryptocurrency: 'Cryptocurrency',
    cryptocurrencies: 'Cryptocurrencies',
    currencies: 'Currencies',
    jurisdiction: 'Jurisdiction',
    amount: 'Amount',
    balance: 'Balance',
    native: 'Native',
    address: 'Address',
    withdrawAddress: 'Withdraw address',
    addressFrom: 'Address from',
    addressTo: 'Address to',
    serviceFee: 'Service fee',
    networkFee: 'Network fee',
    totalFee: 'Total fee',
    totalAmount: 'Total amount',
    total: 'Total',
    totalIn: 'Total in {code}',
    overview: 'Overview',
    balances: 'Balances',
    balanceStatement: 'Balance statement',
    merchant: 'Merchant',
    verification: 'Verification',
    verificationToken: 'Verification token',
    edit: 'Edit',
    displayName: 'Display name',
    description: 'Description',
    defaultUrl: 'Default URL',
    successUrl: 'Success URL',
    cancelUrl: 'Cancel URL',
    supportEmail: 'Support email',
    manualApprovalStatus: 'Manual approval status',
    txid: 'TxID',
    fee: 'Fee',
    feeUSD: 'Fee in USD',
    fees: 'Fees',
    feeType: 'Fee type',
    feeAmount: 'Fee amount',
    feeAmountCurrency: 'Fee amount currency',
    feePercent: 'Fee %',
    updateFeeAmount: 'Update fee amount',
    updateFeePercent: 'Update fee %',
    featureType: 'Feature type',
    bank: 'Bank',
    bankFee: 'Bank fee',
    whitelist: 'Whitelist',
    actions: 'Actions',
    fiatOperations: 'Fiat operations',
    massPayouts: 'Mass payouts',
    massPayoutsFee: 'Mass payouts fee',
    paymentGateway: 'Payment gateway',
    exchange: 'Exchange',
    from: 'From',
    to: 'To',
    withdraw: 'Withdraw',
    deposit: 'Deposit',
    applicableUpTo: 'Applicable up to',
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    number: 'Number #',
    iban: 'IBAN',
    bicSwift: 'BIC/SWIFT',
    credit: 'Credit',
    debit: 'Debit',
    riskScore: 'Risk score',
    exchangeRate: 'Exchange rate',
    customerName: 'Customer name',
    customer: 'Customer',
    rejectReason: 'Reject reason',
    withdrawalId: 'W #',
    note: 'Note',
    createdAUtc: 'Created at (UTC)',
    processedBankAccount: 'Processed bank account',
    bankPaymentNumber: 'Bank payment number',
    processedDate: 'Processed date',
    processedBy: 'Processed by',
    bankStatus: 'Bank status',
    userStatus: 'User status',
    nameOnAccount: 'Name on account',
    refId: 'Ref ID',
    paidFrom: 'Paid from',
    transactions: 'Transactions',
    linkedTransactions: 'Linked transactions',
    device: 'Device',
    organization: 'Organization',
    organizationId: 'Organization ID',
    organizationName: 'Organization name',
    account: 'Account',
    accountId: 'Account ID',
    accountNum: 'Account number',
    startingFrom: 'Starting from',
    kytProvider: 'KYT Provider',
    withdrawRequestCurrency: 'Withdrawal request currency',
    invoiceId: 'Invoice Id',
    hash: 'Hash',
    paidAmount: 'Paid amount',
    sessionHash: 'Session hash',
    requestedInCrypto: 'Requested in Crypto',
    paymentAuditType: 'Audit type',
    defaultAddresses: 'Default addresses',
    defaultSettings: 'Default settings',
    startBalance: 'Start balance',
    endBalance: 'End balance',
    startBalanceIn: 'Start balance in {currency}',
    endBalanceIn: 'End balance in {currency}',
    crypto: 'Crypto',
    fiat: 'Fiat',
    settings: 'Settings',
    signed: 'Signed',
    stream: 'Stream',
    counterparty: 'Counterparty',
    gross: 'Gross',
    grossUSD: 'Gross in USD',
    net: 'Net',
    netUSD: 'Net in USD',
    token: 'Token',
    spreads: 'Spreads',
    percentage: 'Percentage',
    updateSpreadPercent: 'Update spread %',
    ipAddress: 'IP address',
    blockDate: 'Block date',
    blockedUntil: 'Blocked until',
    country: 'Country',
    browser: 'Browser',
    operatingSystem: 'OS',
    expiration: 'Expiration',
    transactionId: 'Transaction ID',
    reason: 'Reason',
    amlId: 'AML ID',
    reversalTransaction: 'Reversal transaction',
    reversalHash: 'Reversal hash',
    reversalAddress: 'Reversal address',
    manager: 'Manager',
    sendersWallet: "Sender's wallet",
    returnedTo: 'Returned to',
    ourWallet: 'Our wallet',
    securityLogs: 'Security logs',
  },
  statuses: {
    init: 'Init',
    pending: 'Pending',
    completed: 'Completed',
    finished: 'Finished',
    processing: 'Processing',
    rejected: 'Rejected',
    active: 'Active',
    blocked: 'Blocked',
    undefined: 'Undefined',
    waiting: 'Waiting',
    expired: 'Expired',
    deleted: 'Deleted',
    failed: 'Failed',
    verified: 'Verified',
    canceled: 'Canceled',
    illegal: 'Illegal',
    compliance: 'Compliance',
    service: 'Service',
    refunded: 'Refunded',
    autoCheckPending: 'Auto check pending',
    autoCheckFailed: 'Auto check failed',
    autoCheckSuccess: 'Auto check success',
    autoCheckRiskLow: 'Auto check risk low',
    autoCheckRiskMedium: 'Auto check risk medium',
    autoCheckRiskHigh: 'Auto check risk high',
    pendingReview: 'Pending review',
    onHold: 'On hold',
    returning: 'Returning',
    returned: 'Returned',
    cleared: 'Cleared',
    seized: 'Seized',
    blockchainConfirmed: 'Blockchain confirmed',
  },
};
