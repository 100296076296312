export enum CurrencyType {
  Fiat = 'fiat',
  Crypto = 'crypto',
}

export enum CurrencyCode {
  USD = 'USD',
  EUR = 'EUR',
}

export interface Currency<T extends string = CurrencyCode> {
  id: number;
  name: string;
  code: T;
  symbol: string;
  logo: string;
}

export enum CryptoCurrencyCoinType {
  Native = 'native',
  ERC20 = 'erc20',
  BEP20 = 'bep20',
  TRC20 = 'trc20',
}

export enum CryptoCurrencyBlockchain {
  Bitcoin = 'bitcoin',
  Litecoin = 'litecoin',
  Dogecoin = 'dogecoin',
  Ethereum = 'ethereum',
  BinanceSmartChain = 'binance-smart-chain',
  Polygon = 'polygon',
  Tron = 'tron',
}

export enum CryptoCurrencyCode {
  DAI = 'DAI',
  USDT = 'USDT',
  USDC = 'USDC',
  BTC = 'BTC',
  BUSD = 'BUSD',
}

export interface CryptoCurrency
  extends Omit<Currency<CryptoCurrencyCode>, 'symbol'> {
  coin_type: CryptoCurrencyCoinType;
  blockchain: CryptoCurrencyBlockchain;
  decimals: number;
  min_exchange_amount: string;
  pretty_name: string;
  order_priority: number;
}

export interface CoinType {
  custom: CryptoCurrencyCoinType | string;
  native: CryptoCurrencyCoinType | string;
  isNative: boolean;
  value: CryptoCurrencyCoinType | string;
  valueWrapped: string;
}

export interface ExtendedCryptoCurrency extends CryptoCurrency {
  blockchainName: string;
  blockchainFullName: string;
  currencyCodeAndName: string;
  currencyCodeAndBlockchain: string;
  coinTypeName: CoinType;
}

export type FiatOrCrypto = ExtendedCryptoCurrency | Currency;
