export default {
  emptyMessageText: 'No transactions found.',
  details: {
    cryptoTitle: 'Crypto transaction details',
    addTxid: 'Add TxID',
    transactionUpdateSuccess: 'Transaction was successfully updated',
    amlReversal: 'AML Reversal',
    amlReversalSuccess: 'Transaction was successfully reversed',
    transactionCancelSuccess: 'Transaction was successfully canceled',
  },
};
