export enum BuildMode {
  Production = 'production',
  Sandbox = 'sandbox',
  Develop = 'development',
}

export default {
  buildMode: (process.env.VUE_APP_BUILD_MODE ?? BuildMode.Develop) as BuildMode,
  host: process.env.VUE_APP_HOST ?? '',
  cookieName: process.env.VUE_APP_COOKIE_NAME ?? '',
  apiURL: process.env.VUE_APP_API_URL ?? '',
  dashboardURL: process.env.VUE_APP_DASHBOARD_URL ?? '',
  landingURL: process.env.VUE_APP_LANDING_URL ?? '',
  authURL: process.env.VUE_APP_AUTH_URL ?? '',
  twoFactorAuthTimeout: 120,
  sumsubLink: 'https://cockpit.sumsub.com/checkus/#/applicant/:id/basicInfo',
  // explorer
  transactionExplorerPathBsc:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_BSC ?? '',
  transactionExplorerPathEth:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_ETH ?? '',
  transactionExplorerPathBtc:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_BTC ?? '',
  transactionExplorerPathLtc:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_LTC ?? '',
  transactionExplorerPathDoge:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_DOGE ?? '',
  transactionExplorerPathPolygon:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_POLYGON ?? '',
  transactionExplorerPathTron:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_TRON ?? '',
};
