import { createStore } from 'vuex';

import { Store } from '@/app/types/store/store';

import access from '@/store/modules/access';
import auth from '@/store/modules/auth';
import dictionaries from '@/store/modules/dictionaries';

export default createStore<Store>({
  strict: process.env.NODE_ENV !== 'production',
  modules: { auth, dictionaries, access },
});
