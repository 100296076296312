import { RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import aml from '@/router/resources/routes/aml';
import bankAccounts from '@/router/resources/routes/bank-accounts';
import dashboard from '@/router/resources/routes/dashboard';
import documents from '@/router/resources/routes/documents';
import exchangeRequests from '@/router/resources/routes/exchange-requests';
import orgAccounts from '@/router/resources/routes/org-accounts';
import organizations from '@/router/resources/routes/organizations';
import paymentAudits from '@/router/resources/routes/payment-audits';
import security from '@/router/resources/routes/security';
import service from '@/router/resources/routes/service';
import sessions from '@/router/resources/routes/sessions';
import settings from '@/router/resources/routes/settings';
import tools from '@/router/resources/routes/tools';
import transactions from '@/router/resources/routes/transactions';
import users from '@/router/resources/routes/users';
import withdrawRequests from '@/router/resources/routes/withdraw-requests';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RouteName.Home,
    redirect: { name: RouteName.Organizations },
  },

  ...dashboard,
  ...service,
  ...users,
  ...organizations,
  ...transactions,
  ...withdrawRequests,
  ...exchangeRequests,
  ...sessions,
  ...bankAccounts,
  ...orgAccounts,
  ...paymentAudits,
  ...aml,
  ...documents,
  ...settings,
  ...tools,
  ...security,
];

export default routes;
