import { RouteLocationNormalized } from 'vue-router';

import { InternalStatus } from '@/app/types/api/config';

import Store from '@/store';
import AccessStoreService from '@/store/services/AccessStoreService';

import ClientResponseError from '@/app/api/factories/ClientResponseError';

export default function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    if (to.name === from.name) {
      return resolve();
    }

    if (state.access.isDeviceVerified) {
      return resolve();
    }

    function handleDeviceVerification() {
      if (!state.access.isDeviceVerified) {
        return reject(
          ClientResponseError.createSimpleError(
            'Device verification error',
            InternalStatus.DeviceNotVerified,
          ),
        );
      }
    }

    const accessService = new AccessStoreService();

    return accessService
      .checkDeviceVerification()
      .then(() => {
        handleDeviceVerification();

        return resolve();
      })
      .catch(handleDeviceVerification);
  });
}
