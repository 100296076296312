import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  // settings
  {
    path: '/settings',
    name: RouteName.Settings,
    redirect: { name: RouteName.KytRotator },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'kyt-rotator',
        name: RouteName.KytRotator,
        component: () => import('@/views/ViewSettings/ViewKytRotator.vue'),
      },
      {
        path: 'addresses',
        name: RouteName.ActiveAddresses,
        component: () => import('@/views/ViewSettings/ViewActiveAddresses.vue'),
      },
      {
        path: 'restricted-addresses',
        name: RouteName.RestrictedAddresses,
        component: () =>
          import('@/views/ViewSettings/ViewRestrictedAddresses.vue'),
      },
      {
        path: 'restricted-ips',
        name: RouteName.RestrictedIps,
        component: () => import('@/views/ViewSettings/ViewRestrictedIps.vue'),
      },
      {
        path: 'allowed-assets',
        name: RouteName.AllowedAssets,
        component: () => import('@/views/ViewSettings/ViewAllowedAssets.vue'),
      },
    ],
  },
  // referral
  {
    path: '/settings/referral',
    name: RouteName.Referral,
    redirect: { name: RouteName.ReferralPartners },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'partners',
        name: RouteName.ReferralPartners,
        component: () =>
          import(
            '@/views/ViewSettings/ViewReferralPartners/ViewReferralPartners.vue'
          ),
      },
      {
        path: 'partners/:id',
        name: RouteName.ReferralPartnerDetails,
        component: () =>
          import(
            '@/views/ViewSettings/ViewReferralPartners/ViewReferralPartnerDetails.vue'
          ),
      },
    ],
  },
];
