import { HttpStatus, InternalStatus } from '@/app/types/api/config';
import { RouteName } from '@/app/types/app/routes';

import ClientResponseError from '@/app/api/factories/ClientResponseError';

import router from '@/router';

export function resolveRoute(name: RouteName) {
  return router.resolve({ name });
}

export function resolveErrorRoute(
  error: Error | ClientResponseError,
  toRouteName: RouteName,
) {
  if (error instanceof ClientResponseError) {
    switch (error.status) {
      // Forbidden
      case HttpStatus.Unauthorized:
        if (toRouteName === RouteName.Login) {
          return;
        }

        return resolveRoute(RouteName.Login);

      // Device Verification
      case InternalStatus.DeviceNotVerified:
        if (toRouteName === RouteName.DeviceVerification) {
          return;
        }

        return resolveRoute(RouteName.DeviceVerification);

      default:
        return;
    }
  }

  return;
}
