<template>
  <component :is="layout" v-if="layoutName === layout.name">
    <router-view />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { Layout } from '@/app/types/app/routes';

import LayoutDefault from '@/layouts/LayoutDefault.vue';
import LayoutEmpty from '@/layouts/LayoutEmpty.vue';

const route = useRoute();

const layoutName = computed(() => route.meta?.layout);

const layout = computed(() => {
  switch (layoutName.value) {
    case Layout.Default:
      return LayoutDefault;
    case Layout.Empty:
      return LayoutEmpty;
    default:
      return LayoutDefault;
  }
});
</script>
