import { GetterTree, MutationTree } from 'vuex';

import { User, UserRole } from '@/app/types/api/resources/users';
import {
  AuthGetters,
  AuthMutations,
  AuthState,
} from '@/app/types/store/modules/auth';
import { Store } from '@/app/types/store/store';

const state = (): AuthState => ({ user: null });

const mutations: MutationTree<AuthState> & AuthMutations = {
  setUser(state: AuthState, user: User) {
    state.user = user;
  },
};

const getters: GetterTree<AuthState, Store> & AuthGetters = {
  hasUser: (state: AuthState): boolean => !!state.user,
  isEmailVerified: (state: AuthState): boolean =>
    !!state.user?.email_verified_at,
  isUserAdmin: (state: AuthState): boolean =>
    state.user?.role === UserRole.Admin,
  userId: (state: AuthState): number | null => state.user?.id ?? null,
  userEmail: (state: AuthState): string => state.user?.email ?? '',
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
  actions: {},
};
