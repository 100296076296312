export default {
  required: 'This field is required',
  emailTemplate: 'Please enter a valid email address',
  ipv4: 'Please enter a valid v4 IP address',
  integer: 'Value should be an integer and bigger than 0',
  integers: 'Only integers allowed',
  minSymbols: 'Minimum {symbol} symbols',
  maxSymbols: 'Maximum {symbol} symbols',
  insufficientBalance: 'Insufficient balance',
  insufficientNativeBalance: 'Insufficient balance of native cryptocurrency',
  standardServerError: 'An error occurred. Refresh page and try again',
  serviceUnavailable:
    'Planned maintenance in progress. Some features unavailable',
  sessionNotFound: 'Session not found',
  transactionNotFound: 'Transaction not found',
  matchedTransaction: 'Transaction is already taken',
  severalUndefinedTransactions:
    'There are several undefined transactions, please contact support',
  matchedSession: 'Session is already taken',
  sessionIsNotExpired: 'Session is not expired yet',
  cryptosDontMatch: 'Cryptocurrencies do not match',
  amountsDontMatch: 'Amounts do not match',
  walletsDontMatch: 'Wallets do not match',
  datesDontMatch: 'Transaction was made before session',
  sessionHasNoIntent: 'Session has no payment intent',
  userNotFound: 'User not found',
  organizationNotFound: 'Organization not found',
  invalidAddressList: 'Please enter valid addresses separated only by commas',
};
