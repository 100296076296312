import { StoreGetter } from '@/app/types/store/store';

import Store from '@/store';

import redirect from '@/app/helpers/redirect';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { getters } = Store;

    const isNotVerified =
      getters[StoreGetter.HasUser] && !getters[StoreGetter.IsEmailVerified];

    if (isNotVerified) {
      redirect('authURL');

      return reject('User email is not verified');
    }

    return resolve();
  });
}
