import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/withdraw-requests',
    name: RouteName.WithdrawRequests,
    redirect: { name: RouteName.CryptoWithdrawRequests },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'crypto',
        name: RouteName.CryptoWithdrawRequests,
        component: () =>
          import('@/views/ViewWithdrawRequests/ViewCryptoWithdrawRequests.vue'),
      },
      {
        path: 'fiat',
        name: RouteName.FiatWithdrawRequests,
        component: () =>
          import('@/views/ViewWithdrawRequests/ViewFiatWithdrawRequests.vue'),
      },
    ],
  },
  {
    path: '/withdraw-requests/crypto/:id',
    name: RouteName.CryptoWithdrawRequest,
    component: () =>
      import('@/views/ViewWithdrawRequest/ViewCryptoWithdrawRequest.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/withdraw-requests/fiat/:id',
    name: RouteName.FiatWithdrawRequest,
    component: () =>
      import('@/views/ViewWithdrawRequest/ViewFiatWithdrawRequest.vue'),
    meta: { layout: Layout.Default },
  },
];
