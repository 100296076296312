import { StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import banksApi from '@/app/api/resources/banks';
import dictionariesApi from '@/app/api/resources/dictionaries';

export default class {
  fetchCurrencies() {
    return dictionariesApi
      .fetchCurrencies()
      .then(({ data }) => Store.commit(StoreCommit.SetCurrencies, data.data));
  }

  fetchCryptoCurrencies() {
    return dictionariesApi
      .fetchCryptocurrencies()
      .then(({ data }) =>
        Store.commit(StoreCommit.SetCryptoCurrencies, data.data),
      );
  }

  fetchJurisdictions() {
    return dictionariesApi.fetchJurisdictions().then(({ data }) => {
      Store.commit(StoreCommit.SetJurisdictions, data.data);
    });
  }

  fetchInxyBankAccounts() {
    return banksApi.fetchInxyBankAccounts().then(({ data }) => {
      Store.commit(StoreCommit.SetInxyBankAccounts, data.data);
    });
  }

  fetchKytProviders() {
    return dictionariesApi.fetchKytProviders().then(({ data }) => {
      Store.commit(StoreCommit.SetKytProviders, data.data);
    });
  }

  fetchDictionaries() {
    return Promise.all([
      this.fetchCurrencies(),
      this.fetchCryptoCurrencies(),
      this.fetchJurisdictions(),
      this.fetchInxyBankAccounts(),
      this.fetchKytProviders(),
    ]);
  }
}
