import { NavigationGuard } from 'vue-router';

import cookies from '@/plugins/cookies';

import env from '@/app/env';

export const redirectDataCookie = 'redirect-data';

const redirectFromGuard: NavigationGuard = (to, from, next) => {
  const redirectedFromPath = to.redirectedFrom || from;

  if (!redirectedFromPath?.name) {
    return next();
  }

  const redirectData = {
    name: redirectedFromPath.name,
    params: redirectedFromPath.params,
    query: redirectedFromPath.query,
  };

  const serializedData = JSON.stringify(redirectData);

  cookies.set(redirectDataCookie, serializedData, 5 * 60, '/', env.host);

  next();
};

export default redirectFromGuard;
