import { useRouter } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import cookies from '@/plugins/cookies';

import env from '@/app/env';

export default function () {
  const router = useRouter();

  function logoutUser() {
    cookies.remove(env.cookieName, '/', env.host);

    router.push({ name: RouteName.Login });
  }

  return {
    logoutUser,
  };
}
