export default {
  emptyMessageText: 'No AML reports found.',
  details: {
    title: 'AML Details',
    statusChangeSuccess: 'AML status was updated successfully.',
    amlHistory: 'AML Log',
    actions: {
      clear: 'Clear',
      onHold: 'On Hold',
      return: 'Return',
      seize: 'Seize',
      pendingReview: 'Pending Review',
    },
  },
  returnRequests: {
    emptyMessageText: 'No return requests found.',
    details: {
      title: 'Return request details',
      completeSuccess: 'Return request was successfully completed.',
    },
  },
};
