export enum DictionariesApi {
  Currencies = '/backoffice/dictionaries/currencies',
  Cryptocurrencies = '/backoffice/dictionaries/cryptocurrencies',
  Jurisdictions = '/backoffice/dictionaries/jurisdictions',
  KytProviders = '/backoffice/dictionaries/providers',
}

export interface JurisdictionsResponse {
  id: number;
  name: JurisdictionsName;
  created_at: string;
  updated_at: string;
}

export enum JurisdictionsName {
  Poland = 'poland',
  Cyprus = 'cyprus',
}

export interface KYTProviderResponse {
  id: number;
  name: KYTProvider;
}

export enum KYTProvider {
  Valegachain = 'valegachain',
  Crystal = 'crystal',
  Inxy = 'inxy',
}
