export default {
  funding: {
    emptyMessageText:
      'No funding instructions found.\n\nTry other filter settings.',
  },
  invoices: {
    emptyMessageText: 'No invoices found.\n\nTry other filter settings.',
  },
  transactionReports: {
    title: 'Monthly transaction reports',
  },
};
