import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/sessions',
    name: RouteName.PaygateSessions,
    component: () =>
      import('@/views/ViewPaygateSessions/ViewPaygateSessions.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/sessions/:hash',
    name: RouteName.PaygateSessionsDetails,
    component: () =>
      import('@/views/ViewPaygateSessions/ViewPaygateSessionDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
