<template>
  <QBtn round flat>
    <QAvatar size="26px">
      <QIcon name="account_circle" size="26px" />
    </QAvatar>

    <QMenu>
      <QList style="min-width: 200px">
        <QItem clickable @click="logoutUser">
          <QItemSection avatar>
            <QIcon name="logout" />
          </QItemSection>

          <QItemSection>{{ t('common.buttons.logOut') }}</QItemSection>
        </QItem>
      </QList>
    </QMenu>
  </QBtn>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import useUserLogout from '@/composables/auth/useUserLogout';

const { t } = useI18n();

const { logoutUser } = useUserLogout();
</script>
