import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { computed } from 'vue';

export default function () {
  const $q = useQuasar();

  const isDarkMode = computed(() => $q.dark.isActive);

  const themeIcon = computed(() =>
    isDarkMode.value ? 'light_mode' : 'dark_mode',
  );

  function toggleDarkMode() {
    $q.dark.toggle();
  }

  return { themeIcon, toggleDarkMode };
}
