import { Layout, RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/transactions',
    name: RouteName.Transactions,
    redirect: { name: RouteName.CryptoTransactions },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'crypto',
        name: RouteName.CryptoTransactions,
        component: () =>
          import('@/views/ViewTransactions/ViewCryptoTransactions.vue'),
      },
      {
        path: 'fiat',
        name: RouteName.FiatTransactions,
        component: () =>
          import('@/views/ViewTransactions/ViewFiatTransactions.vue'),
      },
      {
        path: 'pending-crypto',
        name: RouteName.PendingCryptoTransaction,
        component: () =>
          import('@/views/ViewTransactions/ViewCryptoPendingTransactions.vue'),
      },
    ],
  },
  {
    path: '/transactions/crypto/:id',
    name: RouteName.CryptoTransaction,
    component: () =>
      import('@/views/ViewTransactions/ViewCryptoTransactionDetails.vue'),
    meta: { layout: Layout.Default },
  },
];
