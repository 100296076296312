import { DictionaryResponse } from '@/app/types/api/config';
import {
  DictionariesApi,
  JurisdictionsResponse,
  KYTProviderResponse,
} from '@/app/types/api/resources/dictionaries';
import { CryptoCurrency, Currency } from '@/app/types/app/currency';

import client from '@/app/api/client';

export default {
  fetchCurrencies(): Promise<DictionaryResponse<Currency[]>> {
    return client.get(DictionariesApi.Currencies);
  },

  fetchCryptocurrencies(): Promise<DictionaryResponse<CryptoCurrency[]>> {
    return client.get(DictionariesApi.Cryptocurrencies);
  },

  fetchJurisdictions(): Promise<DictionaryResponse<JurisdictionsResponse[]>> {
    return client.get(DictionariesApi.Jurisdictions);
  },

  fetchKytProviders(): Promise<DictionaryResponse<KYTProviderResponse[]>> {
    return client.get(DictionariesApi.KytProviders);
  },
};
